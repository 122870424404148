@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Aeroport";
  src: url("/public/fonts/Aeroport-Medium.ttf");
}

:root {
  --toastify-toast-width: 420px;
}

.limbic-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 100ms, opacity 100ms linear;
}

* {
  scroll-behavior: smooth;
}

.DayPicker {
  font-weight: 300;
}

input.rc-time-picker-input,
input.rc-time-picker-panel-input {
  font-family: "Aeroport";
  font-weight: 300;
  font-size: 16px;
  border: 0;
  padding: 0;
  color: #1f2133;
  outline: 0;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-btn-primary:not(:disabled):active {
  color: #fff;
  background-color: #fd81a0;
}

.ant-picker-ok button.ant-btn {
  background-color: #fd81a0;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-btn-primary:not(:disabled):hover {
  background-color: #ffa7bd;
}

li.rc-time-picker-panel-select-option-selected {
  font-weight: 500;
}

.react-datepicker {
  font-family: var(--font-aeroport) !important;
}

.datepicker-container .react-datepicker {
  border: 0;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__header {
  background-color: transparent;
  border: 0;
}

.react-datepicker .react-datepicker__day-names {
  margin-bottom: -4px;
}

.react-datepicker .react-datepicker__day--in-range,
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--selected:hover {
  background-color: #e9eaf1;
  color: #373d62;
  border-radius: 0;
}

.react-datepicker .react-datepicker__day--keyboard-selected {
  background-color: transparent1;
}

.react-datepicker .react-datepicker__month {
  margin: 0;
}

.react-datepicker .react-datepicker__week .react-datepicker__day--range-start {
  border-radius: 20px 0 0 20px;
}

.react-datepicker .react-datepicker__week .react-datepicker__day--range-end {
  border-radius: 0 20px 20px 0;
  background-color: #373d62;
  color: #fff;
}

.react-datepicker .react-datepicker__day:hover {
  border-radius: 0;
}

.react-datepicker .react-datepicker__day--in-range:hover {
  background-color: #373d62;
  color: #fff;
  border-radius: 0;
}

.react-datepicker .react-datepicker__day {
  color: #373d62;
  margin: 0;
  width: 33px;
  height: 22px;
  margin: 0;
  padding: 0;
  line-height: 22px;
}

.react-datepicker .react-datepicker__week {
  margin-top: 2px;
}

.react-datepicker
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker .react-datepicker__day--in-range,
    .react-datepicker .react-datepicker__month-text--in-range,
    .react-datepicker .react-datepicker__quarter-text--in-range,
    .react-datepicker .react-datepicker__year-text--in-range
  ),
.react-datepicker
  .react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker .react-datepicker__day--in-range,
    .react-datepicker .react-datepicker__month-text--in-range,
    .react-datepicker .react-datepicker__quarter-text--in-range,
    .react-datepicker .react-datepicker__year-text--in-range
  ) {
  background-color: #e9eaf1 !important;
  border-radius: 0;
}

.react-datepicker .react-datepicker__header .react-datepicker__day-name:first-letter {
  font-size: 14px;
}

.react-datepicker .react-datepicker__header {
  padding-bottom: 0;
}

.react-datepicker .react-datepicker__header .react-datepicker__day-name {
  font-size: 0;
  width: 28px;
  line-height: 15px;
  color: #8389af;
}

.react-datepicker .react-datepicker__current-month {
  font-weight: 500;
  color: #212645;
}

.react-datepicker .react-datepicker__navigation-icon--previous::before {
  border: 5px solid transparent !important;
  border-top-color: black !important;
  border-left-color: black !important;
  transform: scale(0.5) rotate(-45deg);
  top: 11px;
}

.react-datepicker .react-datepicker__navigation-icon--next::before {
  border: 5px solid transparent !important;
  border-top-color: black !important;
  border-right-color: black !important;
  transform: scale(0.5) rotate(45deg);
  top: 11px;
}

.react-datepicker .react-datepicker__current-month {
  margin-bottom: 10px;
}

.react-datepicker .react-datepicker__day--disabled {
  color: #ccc;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
  color: white;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
  color: white;
}

.ant-carousel .slick-list {
  border-radius: 0.5rem;
}

@layer utilities {
  .therapist-font {
    font-family: var(--font-aeroport);
  }

  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
